import { find, filter, map } from 'lodash'
import React, { FormEvent, useEffect, useState } from 'react'
import { useUser } from '@coachmate/user'
import { useEntity } from '@coachmate/entity'
import { createAccount } from '@coachmate/account'

import { GqlCoreAdminUserFieldsFragment } from '@graphql'
import {
  Button,
  ERROR_MESSAGE,
  Label,
  Section,
  Select,
  SelectOption,
  sentryService,
  Text,
  TextSkeleton,
  useAnalytics,
  useModal,
  useRunQuery,
  useToast,
  utilService,
} from '@coachmate/common'

type Props = {
  adminUser: GqlCoreAdminUserFieldsFragment
  refetch: () => void
}

export const EnrolUserInSportModal = ({ adminUser, refetch }: Props) => {
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { entities } = useEntity()
  const { openToast } = useToast()
  const { closeModal } = useModal()
  const { isSuperuser } = useUser()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedEntityId, setSelectedEntityId] = useState<string>('')
  const [entityOptions, setEntitiesOptions] = useState<SelectOption<string>[]>([])

  useEffect(() => {
    ;(async () => {
      track('admin_user_manage_accounts_modal_open', { userId: adminUser.user.id })

      if (!isSuperuser) {
        closeModal()
        openToast(<Text>{ERROR_MESSAGE.permission}</Text>, 'danger')
        return
      }

      await runQuery(async () => {
        const filteredEntities = filter(entities, ({ id }) => !find(adminUser.accounts, { entityId: id }))

        if (!filteredEntities.length) {
          openToast(`There are no entities available for ${utilService.getFullName(adminUser.user)}.`)

          closeModal()
          return
        }

        const entityOptions = map(filteredEntities, ({ id, name }) => ({ value: id, label: name }))

        setEntitiesOptions(entityOptions)
        setSelectedEntityId(entityOptions[0]?.value || '')
        setIsLoading(false)
      })
    })()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!selectedEntityId) {
      return
    }

    track('admin_user_enrol_in_sport_submit', { userId: adminUser.user.id, entityId: selectedEntityId })
    setIsSaving(true)

    try {
      await runQuery(async () => {
        await createAccount({ userId: adminUser.user.id, entityId: selectedEntityId })
        refetch()
        closeModal()
      })
    } catch (error: any) {
      setIsSaving(false)
      openToast(<Text>Something went wrong</Text>, 'danger')
      sentryService.captureException({ exception: error, extras: { entityId: selectedEntityId } })
      track('admin_user_enrol_in_sport_submit_error', { userId: adminUser.user.id, entityId: selectedEntityId, error: error.message })
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <TextSkeleton className="h-4 w-20 mb-1" />
          <TextSkeleton className="h-10 w-full" />
          <div className="flex items-center justify-end mt-4">
            <TextSkeleton className="h-10 w-24 mr-3" />
            <TextSkeleton className="h-10 w-24" />
          </div>
        </>
      )
    }

    return (
      <>
        <Label>Entity</Label>
        <Select options={entityOptions} value={selectedEntityId} onChange={(entityId) => setSelectedEntityId(entityId)} />
        <div className="flex items-center justify-end mt-4">
          <Button className="mr-3" onClick={closeModal} variant="neutral" state="outline" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isSaving}>
            Enrol User
          </Button>
        </div>
      </>
    )
  }

  return (
    <form className="w-[600px] max-w-sm" onSubmit={handleSubmit}>
      <Section className="py-5">
        <Text className="text-center mb-4">
          Which sport would you like to enrol <strong>{utilService.getFullName(adminUser.user)}</strong> in?
        </Text>
        {renderContent()}
      </Section>
    </form>
  )
}
