import { gql } from '@apollo/client'

export const ENTITY_TERMINOLOGY_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreEntityTerminologyFields on EntityTerminology {
    drill
    drills
  }
`

export const ENTITY_CORE_FIELDS_FRAGMENT = gql`
  ${ENTITY_TERMINOLOGY_CORE_FIELDS_FRAGMENT}

  fragment CoreEntityFields on Entity {
    id
    name
    sport
    region
    state
    coverImage1x2Url
    logoImageUrl
    terminology {
      ...CoreEntityTerminologyFields
    }
  }
`
